import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import styled from '@emotion/styled'

interface ButtonProps {
	color: string
	name: string
	link: string
	fill: boolean
	sitePadding: string
	target: boolean
}

const Link = styled.a<{ padding: string }>`
	button {
		${tw`border-solid border-2 p-2 rounded cursor-pointer`}
		padding : ${({ padding }) => (padding ? `0.5rem ${padding}` : '0.5rem')};
	}
	&.outline {
		button.blue {
			${tw`bg-transparent text-dolphin-gray hover:bg-blue-400 hover:text-white`}
		}
		button.yellow {
			${tw`bg-transparent text-dolphin-gray hover:bg-yellow-400 hover:text-white`}
		}
		button.red {
			${tw`bg-transparent text-dolphin-gray hover:bg-red-400 hover:text-white`}
		}
		button.green {
			${tw`bg-transparent text-dolphin-gray hover:bg-green-400 hover:text-white`}
		}
		button.grey-blue-bis {
			${tw`bg-transparent text-dolphin-gray hover:bg-dolphin-gray hover:text-white`}
		}
		button.white {
			${tw`bg-transparent text-white hover:bg-dolphin-gray hover:text-white`}
		}
	}
`
const BlueButton = tw.button`border-blue-500 bg-blue-500 hover:bg-blue-400 hover:border-blue-400 text-white`
const YellowButton = tw.button`border-yellow-500 bg-yellow-500 hover:bg-yellow-400 hover:border-yellow-400 text-dolphin-gray`
const RedButton = tw.button`border-red-500 bg-red-500 hover:bg-red-400 hover:border-red-400 text-dolphin-gray`
const GreenButton = tw.button`border-green-500 bg-green-500 hover:bg-green-400 hover:border-green-400 text-dolphin-gray`
const GreyBlueBis = tw.button`border-dolphin-gray bg-dolphin-gray hover:bg-transparent hover:border-dolphin-gray hover:text-dolphin-gray text-white`
const White = tw.button`border-white bg-green-500 hover:bg-green-400 hover:border-dolphin-gray text-dolphin-gray`

const Button = (props: ButtonProps): JSX.Element => {
	const { color, name, link, fill, sitePadding, target } = props
	return (
		<Link href={`${link}`} padding={sitePadding} target={target ? '_blank' : ''} className={!fill ? 'outline' : ''}>
			{color === 'green' ? <GreenButton className="green">{name}</GreenButton> : ''}
			{color === 'yellow' ? <YellowButton className="yellow">{name}</YellowButton> : ''}
			{color === 'red' ? <RedButton className="red">{name}</RedButton> : ''}
			{color === 'blue' ? <BlueButton className="blue">{name}</BlueButton> : ''}
			{color === 'white' ? <White className="white">{name}</White> : ''}
			{color === 'grey-blue-bis' ? <GreyBlueBis className="grey-blue-bis">{name}</GreyBlueBis> : ''}
		</Link>
	)
}

Button.propTypes = {
	name: PropTypes.string,
	color: PropTypes.string,
	link: PropTypes.string,
	fill: PropTypes.bool,
}

Button.defaultProps = {
	name: 'Button',
	color: 'blue',
	link: '#',
	fill: false,
}

export default Button
