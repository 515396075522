import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { graphql } from 'gatsby'
import Hero from '../../../sea-site/src/components/product-line/hero'
import Products from '../../../sea-site/src/components/product-line/products'
import LineDescription from '../../../sea-site/src/components/product-line/line-description'
import Faq from '../../../sea-site/src/components/product-line/faq'
import WhichModel from '../components/which-model'
import { ProductLineProp } from './product-lines'

const ProductLines = ({
	pageContext: { series },
	data: { line, lastBanner, productImage, lineLg, lineSm },
}: ProductLineProp): JSX.Element => {
	const productSeries = series != 'O3 Line' ? series : 'O₃ Line'
	const prodSeries = productSeries != 'Wave Line' ? productSeries : 'Wave'
	const [{ node: filteredLine }] = line.edges
	const [{ node: filteredLastBanner }] = lastBanner.edges
	const [{ node: linelg }] = lineLg.edges
	const [{ node: linesm }] = lineSm.edges

	const {
		seoTitle,
		seoKeywords,
		seoDescription,
		body,
		active,
		series: seriesName,
		banner: {
			mainActionText,
			mainActionUrl,
			secondActionText,
			secondActionUrl,
			description,
			image,
			backgroundImage: herobg,
			videoBanner: videourl,
		},
		frequentlyAskedQuestions,
	} = filteredLine
	const title = seoTitle
	const titleSplit = title.split(' ')
	const productBrand = titleSplit.find((ttl) => ttl === 'Mineral' || ttl === 'Ozone')
	const bannerSources = [
		{
			...herobg.fluid,
			srcSet: `${herobg.fluid.src} 1920w`,
			srcSetWebp: `${herobg.fluid.srcWebp} 1920w`,
			media: `(min-width: 1350px)`,
		},
		{
			...linelg.banner.backgroundImage.fluid,
			srcSet: `${linelg.banner.backgroundImage.fluid.src} 1349w`,
			srcSetWebp: `${linelg.banner.backgroundImage.fluid.srcWebp} 1349w`,
			media: `(min-width: 1280px) and (max-width: 1349px)`,
		},
		{
			...linesm.banner.backgroundImage.fluid,
			srcSet: `${linesm.banner.backgroundImage.fluid.src} 1279w`,
			srcSetWebp: `${linesm.banner.backgroundImage.fluid.srcWebp} 1279w`,
			media: `(min-width: 1024px) and (max-width: 1279px)`,
		},
	]

	return (
		<Layout>
			<SEO title={seoTitle} keywords={seoKeywords} description={seoDescription}></SEO>
			<Hero
				{...{
					mainActionText,
					mainActionUrl,
					secondActionText,
					secondActionUrl,
					description,
					videourl,
					image,
					seriesName,
					bannerSources,
					active,
				}}
			/>
			<LineDescription body={body} />
			<Products productImage={productImage} prodSeries={prodSeries} />
			<Faq faqs={frequentlyAskedQuestions} />
			{productBrand === 'Mineral' || productBrand === 'Ozone' ? '' : <WhichModel data={filteredLastBanner} />}
		</Layout>
	)
}

export const ProductLinePageQuery = graphql`
	query ProductLine($slug: String!) {
		line: allContentfulPage(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					seoTitle
					seoKeywords
					seoDescription
					slug
					series
					active
					body {
						raw
					}
					banner {
						hook
						mainActionText
						mainActionUrl
						secondActionText
						secondActionUrl
						backgroundImage {
							fluid(maxWidth: 1920, maxHeight: 720, quality: 60) {
								src
								srcWebp
							}
						}
						image {
							description
							file {
								url
							}
						}
						videoBanner {
							file {
								url
							}
						}
						description {
							raw
						}
					}
					frequentlyAskedQuestions {
						title
						description {
							description
						}
					}
					media {
						file {
							url
						}
					}
					brochure {
						file {
							url
						}
					}
					manual {
						file {
							url
						}
					}
				}
			}
		}
		lineLg: allContentfulPage(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					banner {
						backgroundImage {
							fluid(maxWidth: 1399, maxHeight: 750, quality: 60) {
								src
								srcWebp
							}
						}
					}
				}
			}
		}
		lineSm: allContentfulPage(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					banner {
						backgroundImage {
							fluid(maxWidth: 767, maxHeight: 440, quality: 60) {
								src
								srcWebp
							}
						}
					}
				}
			}
		}
		lastBanner: allContentfulBanner(filter: { contentful_id: { eq: "5UBkM4QSv0bHOcKjyMBmw1" } }) {
			edges {
				node {
					hook
					node_locale
					mainActionText
					mainActionUrl
					description {
						raw
					}
					backgroundImage {
						file {
							url
						}
					}
				}
			}
		}
		productImage: allContentfulProduct {
			edges {
				node {
					node_locale
					series
					seoTitle
					slug
					model
					categories {
						slug
					}
					images {
						fluid(maxWidth: 600, maxHeight: 400, quality: 60) {
							src
							srcWebp
						}
						description
					}
				}
			}
		}
	}
`

export default ProductLines
