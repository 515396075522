import React from 'react'
import { BLOCKS, Block, Inline } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { LineDescriptionContainer, Heading2, Text } from './styles'

interface LineDescriptionProp {
	body: {
		raw: string
	}
}

const LineDescription = ({ body }: LineDescriptionProp): JSX.Element => {
	const options = {
		renderNode: {
			[BLOCKS.HEADING_2]: function HeadingTwo(
				node: Block | Inline | Text,
				children: React.ReactChild | React.ReactFragment | null | undefined
			): JSX.Element {
				const words = ['Dolphin', 'Mineral Swim']
				const x = words.map((x) => {
					if (children !== null && children !== undefined) {
						if (children.toString().includes(x)) {
							return `${x}`
						} else {
							return ''
						}
					}
				})
				const ind = children?.toString().includes('Dolphin') ? 0 : 1
				return (
					<Heading2>
						{x[ind]}
						<span>{children?.toString().split(x[ind] as string)}</span>
					</Heading2>
				)
			},
			[BLOCKS.PARAGRAPH]: function Paragraph(
				node: Block | Inline | Text,
				children: React.ReactChild | React.ReactFragment | null | undefined
			): JSX.Element {
				return <Text>{children}</Text>
			},
		},
	}

	return <LineDescriptionContainer>{documentToReactComponents(JSON.parse(body.raw), options)}</LineDescriptionContainer>
}

export default LineDescription
