import React, { useState } from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import posed from 'react-pose'
import { ChevronDown } from 'react-feather'

interface FaqProps {
	title: string
	body: string
}

const FaqItems = styled.article`
	${tw`relative pl-2 md:pl-8 pr-2 md:pr-4 border-dolphin-gray border-t border-b overflow-hidden`}

	&:first-of-type {
		${tw`border-b border-t-2`}
	}

	&:last-of-type {
		${tw`border-t border-b-2`}
	}

	p,
	h4 {
		${tw`pr-8`}
	}

	> p {
		${tw`text-sm`}
	}
`
const FaqItemsTtl = styled.h4`
	${tw`relative font-display text-lg cursor-pointer py-3`}

	svg {
		${tw`absolute right-0 cursor-pointer`}
		transition: all 0.3s linear;
		color: #707b85;
		top: 25%;
	}

	svg.up {
		transform: rotate(180deg);
	}

	svg.down {
		transform: rotate(0);
	}
`

const FaqItemsInfo = posed.p({
	closed: { height: 0, marginBottom: 0 },
	open: { height: 'auto', marginBottom: 15 },
})
const Faqs = ({ title, body }: FaqProps): JSX.Element => {
	const [isOpen, toggleInfo] = useState(false)

	const toggleAccordion = () => {
		toggleInfo((isOpen) => !isOpen)
	}

	return (
		<FaqItems>
			<FaqItemsTtl onClick={toggleAccordion}>
				{title}
				{isOpen ? (
					<ChevronDown width="30" height="30" className="up" />
				) : (
					<ChevronDown width="30" height="30" className="down" />
				)}
			</FaqItemsTtl>
			<FaqItemsInfo pose={isOpen ? 'open' : 'closed'}>{body}</FaqItemsInfo>
		</FaqItems>
	)
}

export default Faqs
