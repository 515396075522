import React from 'react'
import Faqs from '../../../../sea-site-theme/src/components/faq'
import Button from '../../../../sea-site-theme/src/components/utils/button'
import { FaqSection, FaqTitle, FaqContainer } from './styles'

interface FaqProps {
	faqs: {
		title: string
		description: {
			description: string
		}
	}[]
}

const Faq = ({ faqs }: FaqProps): JSX.Element => {
	return (
		<>
			<FaqSection>
				<FaqTitle>Frequently Asked Questions</FaqTitle>
				<FaqContainer>
					{faqs.map((item, i: number) => {
						return <Faqs key={i} title={item.title} body={item.description.description} />
					})}
				</FaqContainer>
				<Button
					color="yellow"
					fill={true}
					name="View more FAQ's"
					link="https://maytronics.com.au/support/faq/"
					sitePadding="3rem"
					target={true}
				/>
			</FaqSection>
		</>
	)
}

export default Faq
